import { useLocation, useParams } from 'react-router-dom';

/**
 * Determines whether the "headerVariant" query parameter is set to enable
 * a focused header variant. This setting is utilized by ReChat through
 * the widget integration.
 *
 * Related task: https://skyslope.atlassian.net/browse/AP-823
 */

export function useHeaderVariant() {
  const { id } = useParams<{ id: string }>();

  const { pathname, search } = useLocation();
  const isSendingEnvelope = pathname.endsWith(`/envelopes/${id}`);
  const headerVariant = new URLSearchParams(search).get('headerVariant');

  const storedHeaderVariant = sessionStorage.getItem('headerVariant');

  if (isSendingEnvelope && (headerVariant === 'focused' || storedHeaderVariant === 'focused')) {
    if (headerVariant === 'focused') {
      sessionStorage.setItem('headerVariant', headerVariant);
    }
    return { variant: 'focused', useDefaultHeader: false };
  }

  return { variant: 'default', useDefaultHeader: true };
}
