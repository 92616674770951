import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useHistory } from 'react-router-dom';
import { KeyboardBackspace } from '@material-ui/icons';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { colors } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab';
import { BLOCK_TYPES, BLOCK_TYPE_KEYS, chameleonElId, EnvelopeStatuses, ResourceNames } from '../../lib/constants';
import { useShallowSelector, useDispatch } from '../../lib/reduxHooks';
import { IRootState } from '../../store';
import {
  FormsBackPage,
  IBlock,
  IBlocks,
  IBlockSizingCache,
  IConfigs,
  IEmailConfig,
  IGroups,
  IPageDimensions,
  ISelectedBlocks,
  ISigner,
} from '../../store/senderBuild/types';
import EditEmailModal from './EditEmailModal';
import * as actions from '../../store/senderBuild/actions';
import { ISettings } from '../../store/pageFrame/types';
import { hex2rgba } from '../../lib/utils';
import WarningFormsModal from './WarningFormsModal';
import validateBlocksAndSendEnvelope from '../../lib/validateBlocksAndSendEnvelope';
import { createRandomId } from '../../lib/randomId';
import MobileBlockSettings from './MobileBlockSettings';
import { isMediumScreenMediaQuery } from '../../lib/isSmallScreen';
import { LaunchDarklyFlags, withLaunchDarkly } from '../../common/launchDarkly';
import { logoutCleanup } from '../../auth';
import { isSkySlopeMobileApp } from '../../common/utils';
import { UserOrigins } from '../../lib/types';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'fixed',
    bottom: 12,
    right: theme.spacing(2),
    zIndex: 1200, // material app bar has a high z-index
    justifyContent: 'flex-end',
    display: 'flex',
    width: 'calc(100VW - 400px)',
  },
  wrapperMobile: {
    width: '100%',
    bottom: '0px',
    right: '0px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    borderTop: '1px solid #CAD5E0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sendWrapper: {
    marginLeft: theme.spacing(1),
    position: 'relative',
    display: 'inline-block',
  },
  mobileSendWrapper: {},
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  backButtonWrapper: {
    display: 'inline-block',
    marginTop: 10,
    flex: 'auto',
  },
  backButtonWrapperMobile: {
    display: 'inherit',
    flex: 'inherit',
    marginTop: '0',
  },
  backButton: {
    '&:hover': {
      backgroundColor: colors.blue[50],
    },
  },
  spacer: {
    padding: '0 12px',
    fontSize: 13,
    display: 'inline-block',
  },
  tab: {
    color: colors.grey[800],
    fontSize: 16,
    opacity: 1,
    minWidth: '28%',
    borderRadius: 0,
    margin: 0,
    padding: '16px 12px 17px',
    textTransform: 'initial',
    fontWeight: 'normal',
    borderRight: `1px solid ${colors.grey[400]}`,
    '&:first-child': {
      borderLeft: `1px solid ${colors.grey[400]}`,
    },
    '&:hover:not($selected)': {
      backgroundColor: `${colors.grey[200]} !important`,
    },
  },
  tabs: {
    width: '100%',
    borderBottom: '1px solid #CAD5E0',
    height: '100%',
  },
  tabsIndicator: {
    display: 'none',
  },
  selected: {
    color: `${colors.grey[800]} !important`,
  },
  buttonContainer: {
    display: 'flex',
    padding: '15px',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  mobileExitButton: {
    height: '40px',
    width: '40px',
    color: colors.blue[800],
    border: `2px solid ${colors.blue[800]}`,
  },
  exitBackWrapper: {
    alignSelf: 'end',
  },
  formsSaveExitButton: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));

interface IState {
  saving: boolean;
  blocks: IBlocks;
  groups: IGroups;
  resource: ResourceNames;
  isAutoSaving: undefined | boolean;
  selectedBlocks: ISelectedBlocks;
  settings: ISettings;
  config?: IConfigs;
  envelopeId: string;
  emailConfig: IEmailConfig;
  activeBlockType: string;
  activeSigner: string;
  signers: ISigner[];
  currentPage: number;
  currentDocument: string;
  pageDimensions: IPageDimensions;
  envelopeStatus: EnvelopeStatuses;
  blockSizingCache: IBlockSizingCache;
  zoom: number;
}

const senderBuildSelector = (state: IRootState) => ({
  selectedBlocks: state.senderBuild.selectedBlocks,
  activeBlockType: state.senderBuild.activeBlockType,
  activeSigner: state.senderBuild.activeSigner,
  signers: state.senderBuild.signers,
});

const footerControlsSelector = (state: IRootState) => ({
  saving: state.senderBuild.saving,
  blocks: state.senderBuild.blocks,
  groups: state.senderBuild.groups,
  resource: state.senderBuild.resource,
  isAutoSaving: state.senderBuild.isAutoSaving,
  settings: state.pageFrame.settings,
  config: state.senderBuild.config,
  envelopeId: state.senderBuild.id,
  emailConfig: state.senderBuild.emailConfig,
  currentPage: state.senderBuild.currentPage,
  currentDocument: state.senderBuild.currentDocument,
  pageDimensions: state.senderBuild.pageDimensions,
  envelopeStatus: state.senderBuild.status,
  blockSizingCache: state.senderBuild.blockSizingCache,
  zoom: state.senderBuild.zoom,
});

const FooterControls = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const {
    saving,
    blocks,
    resource,
    settings,
    envelopeId,
    emailConfig,
    config,
    groups,
    currentPage,
    currentDocument,
    pageDimensions,
    envelopeStatus,
    blockSizingCache,
    zoom,
  }: IState = useShallowSelector(footerControlsSelector);
  const state: IState = useShallowSelector(senderBuildSelector);
  const isMdScreen = isMediumScreenMediaQuery();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const isFormsUser = settings?.account.userOrigin === UserOrigins.Forms;
  const hideUIItems = flags && flags['hide-build-ui-elements'];
  const [showEditEmailModal, setShowEditEmailModal] = React.useState(false);
  const blockTypeArray = BLOCK_TYPES.slice(1);
  const confirmedNavigationRef = useRef<boolean>(false);
  const showWarningModalFlag = flags && flags['forms-warning-modal'];
  const showSmartEmail = flags && flags['show-smart-email'];
  const [showWarningFormsModal, setShowWarningFormsModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState<() => (() => void) | undefined>();
  const isMobileApp = isSkySlopeMobileApp();

  const beforeUnloadCallBack = (event: BeforeUnloadEvent) => {
    if (!confirmedNavigationRef.current) {
      const confirmationMessage = 'Changes you have entered will not be saved.';
      event.preventDefault();
      // eslint-disable-next-line no-return-assign, no-param-reassign
      return (event.returnValue = confirmationMessage);
    }
  };

  useEffect(() => {
    if (!isFormsUser || confirmedNavigationRef.current || !showWarningModalFlag) return () => {};
    window.addEventListener('beforeunload', beforeUnloadCallBack);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallBack);
    };
  }, []);

  const currentSigner =
    state.activeSigner === 'default'
      ? (state.signers.find((signer: ISigner) => signer.signerId === '1') as ISigner)
      : state.signers.find((signer: ISigner) => signer.signerId === state.activeSigner)!;

  const toggleModal = () => {
    const emailSubject = emailConfig.subject?.trim() || '';
    const emailBody = emailConfig.body?.trim() || '';
    if (hideUIItems) {
      const updatedBlocksAndGroups = validateBlocksAndSendEnvelope(blocks, groups, true, zoom);
      if (updatedBlocksAndGroups) {
        dispatch(
          actions.validateBlocksBeforeSending(
            updatedBlocksAndGroups.updatedBlocks,
            updatedBlocksAndGroups.updatedGroups,
            { subject: emailSubject || '', body: emailBody },
            true
          )
        );
      } else {
        dispatch(actions.updateEmailConfig(envelopeId, { subject: emailSubject, body: emailBody }, true));
      }
    } else {
      setShowEditEmailModal(!showEditEmailModal);
      const sb = state.selectedBlocks;
      if (sb.blockIndices.length > 0) {
        dispatch(actions.selectBlocks(sb.documentId!, sb.pageIndex!, []));
      }
    }
  };

  const handleBackButtonClick = () => {
    if (!isFormsUser) {
      window.history.back();
    } else {
      confirmedNavigationRef.current = true;
      handleExitForms();
    }
  };

  const documentHasABlock = () => {
    for (const docId in blocks) {
      for (const pageNum in blocks[docId]) {
        if (blocks[docId][pageNum]?.some((b) => !b.isEditingDisabled)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleFormsSaveAndExitClick = () => {
    confirmedNavigationRef.current = true;
    logoutCleanup();
    if (config?.senderUi?.sendRedirectUrl) {
      const formsRedirectUrl = new URL(config.senderUi.sendRedirectUrl);
      window.location.href = `${formsRedirectUrl.origin}${formsRedirectUrl.pathname}`;
    } else {
      window.history.back();
    }
  };

  const toggleWarningModal = (buttonFunction?: () => void) => {
    if (isFormsUser && showWarningModalFlag) {
      setShowWarningFormsModal(!showWarningFormsModal);
      setConfirmAction(() => buttonFunction);
    } else if (buttonFunction) buttonFunction();
  };

  const handleExitForms = () => {
    logoutCleanup();
    if (config?.senderUi?.sendRedirectUrl && showWarningModalFlag) {
      const params = new URLSearchParams(window.location.search);
      const backPage = params.get('backPage');
      const formsRedirectUrl = new URL(config.senderUi.sendRedirectUrl);
      const formsEnvelopeId = formsRedirectUrl.searchParams.get('sentEnvelopeId');
      if (backPage && formsEnvelopeId) {
        switch (backPage) {
          case FormsBackPage.Envelopes:
            window.location.href = `${formsRedirectUrl.origin}${formsRedirectUrl.pathname}?revertedEnvelopeId=${formsEnvelopeId}`;
            break;
          case FormsBackPage.Fill:
            window.location.href = `${formsRedirectUrl.origin}/envelopes/${formsEnvelopeId}/fill?revertedEnvelopeId=${formsEnvelopeId}`;
            break;
          default:
            window.history.back();
        }
      } else {
        window.history.back();
      }
    } else {
      window.history.back();
    }
  };

  const handleConfirmNavigationClick = () => {
    if (confirmAction) {
      confirmAction();
    } else {
      window.history.back();
    }
  };

  const handleExitButtonClick = () => {
    if (!isFormsUser) {
      history.push(resource === ResourceNames.ENVELOPE ? '/envelopes' : '/templates');
    } else {
      confirmedNavigationRef.current = true;
      handleExitForms();
    }
  };

  const disabled = !documentHasABlock();
  const isEnvelope = resource === ResourceNames.ENVELOPE;
  const wrapperClass = isMdScreen ? `${classes.wrapper} ${classes.wrapperMobile}` : classes.wrapper;
  const backButtonClass = isMdScreen
    ? `${classes.backButtonWrapper} ${classes.backButtonWrapperMobile}`
    : classes.backButtonWrapper;
  const emailModalAction = isMdScreen ? 'Send' : 'Send for Signatures';

  const createMobileBlock = (type: string) => {
    const currentBlockType = BLOCK_TYPES.find((bt) => bt.key === type);
    let height;
    let width;
    if (blockSizingCache[type] && type !== BLOCK_TYPE_KEYS.STRIKE) {
      height = blockSizingCache[type].height;
      width = blockSizingCache[type].width;
    } else {
      height = currentBlockType?.size?.default.height;
      width = currentBlockType?.size?.default.width;
    }

    let x: number = pageDimensions[currentDocument][currentPage].width / 2;
    let y: number = pageDimensions[currentDocument][currentPage].height / 2;
    if (x + width > pageDimensions[currentDocument][currentPage].width) {
      // get diff that the block is over
      // subtract that from x
      const overlapDiffX = x + width - pageDimensions[currentDocument][currentPage].width;
      x = x - overlapDiffX;
    }
    if (y + height > pageDimensions[currentDocument][currentPage].height) {
      // get diff that the block is over
      // subtract that from y
      const overlapDiffY = y + height - pageDimensions[currentDocument][currentPage].height;
      y = y - overlapDiffY;
    }

    const isStrikeBlock = type === BLOCK_TYPE_KEYS.STRIKE;
    const newBlock: IBlock = {
      blockId: createRandomId(),
      blockType: type,
      pageNumber: currentPage,
      x,
      y,
      fontSize: currentBlockType.size.fontSize,
      width: isStrikeBlock ? 80 : width,
      height,
      required: true,
      assignedTo: isStrikeBlock ? null : state.activeSigner,
      isEditingDisabled: false,
    };
    dispatch(actions.createNewBlock(currentDocument, currentPage, newBlock, true));

    // AP-277: this is enabling editing on placemet
    // if (type === BLOCK_TYPE_KEYS.TEXT) {
    //   dispatch(actions.enableTextEditing());
    // }
  };

  const handleBlockTabClick = (type: string) => {
    dispatch(actions.changeActiveBlockType(type));
    createMobileBlock(type);
  };

  const blockArea = () => {
    if (!isMdScreen) {
      return '';
    }

    if (state.selectedBlocks.blockIndices.length) {
      return <MobileBlockSettings />;
    }

    return (
      <Tabs
        classes={{ root: classes.tabs, indicator: classes.tabsIndicator }}
        value={state.activeBlockType || false}
        variant="scrollable"
        scrollButtons="off"
        data-spec="blockTabs"
        style={{ overflowY: 'hidden' }}
        id={chameleonElId(4)}
      >
        {blockTypeArray
          .filter((b) => b.key !== BLOCK_TYPE_KEYS.RECTANGLE)
          .map((type) => {
            const selected = state.activeBlockType === type.key;
            const backgroundColor = selected ? hex2rgba(currentSigner.color!, 0.1) : 'inherit';
            const boxShadow = selected ? `0 3px 0 0 ${currentSigner.color} inset` : 'none';

            return (
              <Tab
                key={type.key}
                style={{ backgroundColor, boxShadow }}
                classes={{ root: classes.tab, selected: classes.selected }}
                className="flex-1 border-none"
                icon={type.icon}
                label={type.name}
                value={type.key}
                disableRipple
                data-spec={`block-tab-${type.key}`}
                tabIndex={0}
                onClick={() => handleBlockTabClick(type.key)}
              />
            );
          })}
      </Tabs>
    );
  };

  const buttonContent = () => {
    return (
      <>
        {isFormsUser && isMdScreen && (
          <IconButton onClick={() => toggleWarningModal(handleBackButtonClick)} className={classes.mobileExitButton}>
            <ArrowBackIosRoundedIcon />
          </IconButton>
        )}
        {!isFormsUser && (
          <div className={backButtonClass}>
            <Button
              data-spec="backButton"
              className={classes.backButton}
              color="primary"
              variant={isMdScreen ? 'outlined' : undefined}
              onClick={() => toggleWarningModal(handleBackButtonClick)}
              style={{ width: isMdScreen ? '140px' : '' }}
            >
              {isMdScreen ? '' : <KeyboardBackspace />} {isMdScreen ? 'Exit' : 'Back'}
            </Button>
          </div>
        )}

        {!hideUIItems && showWarningModalFlag && showWarningFormsModal ? (
          <WarningFormsModal closeModal={toggleWarningModal} confirmBack={handleConfirmNavigationClick} />
        ) : null}
        {isEnvelope ? (
          <div className={!isMdScreen ? classes.exitBackWrapper : ''}>
            {(hideUIItems && !isMdScreen) || (isFormsUser && envelopeStatus === EnvelopeStatuses.CORRECTING) ? (
              ''
            ) : (
              <Button
                color="primary"
                onClick={() => toggleWarningModal(handleExitButtonClick)}
                disabled={saving}
                data-spec="exitButton"
                style={{ display: isMdScreen ? 'none' : '' }}
              >
                Exit
              </Button>
            )}
            {isFormsUser && showWarningModalFlag && !isMdScreen && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleFormsSaveAndExitClick}
                className={classes.formsSaveExitButton}
                data-spec="formsSaveExitButton"
              >
                Save & Exit
              </Button>
            )}
            <div className={isMdScreen ? classes.mobileSendWrapper : classes.sendWrapper}>
              <Button
                data-spec="sendButton"
                variant="contained"
                color="primary"
                onClick={toggleModal}
                disabled={disabled || saving}
                style={{ width: isMdScreen ? '140px' : '' }}
              >
                Send
              </Button>
              {showEditEmailModal ? (
                <EditEmailModal
                  fullScreen={isMdScreen}
                  toggleModal={toggleModal}
                  subject={showSmartEmail ? 'Save time with smart emails' : 'Ready to send?'}
                  action={emailModalAction}
                  send
                  flags={flags}
                  envelopeId={envelopeId}
                />
              ) : null}
              {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        ) : (
          <Button variant="contained" color="primary" onClick={() => history.push('/templates')}>
            Save & Exit
          </Button>
        )}
      </>
    );
  };

  const buttonArea = () => {
    if (isMobileApp) {
      return '';
    }
    if (isMdScreen) {
      return <div className={classes.buttonContainer}>{buttonContent()}</div>;
    }
    return buttonContent();
  };

  return (
    <div id="footer" className={wrapperClass}>
      {blockArea()}
      {buttonArea()}
    </div>
  );
};

export default withLaunchDarkly(FooterControls);
