import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BLOCK_TYPE_KEYS, ResourceNames, preferencesUrl } from '../lib/constants';
import * as actions from '../store/senderBuild/actions';
import { useDispatch, useShallowSelector } from '../lib/reduxHooks';
import {
  ISelectedBlocks,
  IBlocks,
  ICopyCache,
  IGroups,
  IPageDimensions,
  IRecipients,
  IConfigs,
  IEmailConfig,
} from '../store/senderBuild/types';
import { IRootState } from '../store';
import PdfViewer from '../components/senderBuild/PdfViewer';
import EnvelopeShelf from '../components/senderBuild/EnvelopeShelf';
import FooterControls from '../components/senderBuild/FooterControls';
import Toolbar from '../components/senderBuild/Toolbar';
import BlockShelfWrapper from '../components/senderBuild/BlockShelfWrapper';
import SavingOverlay from '../components/senderBuild/SavingOverlay';
import Done from '../components/senderBuild/Done';
import { pasteCacheBlocks } from '../lib/utils';
import { ISettings } from '../store/pageFrame/types';
import { HeaderContext } from '../context/header-context';
import EnvelopeSent from './EnvelopeSent';
import InfoBoxDialog from '../components/senderBuild/InfoBoxDialog';
import { getUsersGroups } from '../lib/api/account/accountApi';
import MobileRecipientsOrder from '../components/senderBuild/MobileRecipientsOrder';
import EditEmailModal from '../components/senderBuild/EditEmailModal';
import validateBlocksAndSendEnvelope from '../lib/validateBlocksAndSendEnvelope';
import { isMediumScreenMediaQuery, isSmallScreenMediaQuery } from '../lib/isSmallScreen';
import NavHeader from '../common/navigation';
import { isSkySlopeMobileApp, postWindowMessage } from '../common/utils';
import { withLaunchDarkly, LaunchDarklyFlags } from '../common/launchDarkly';
import { UserOrigins } from '../lib/types';
import useUserInfo from '../auth/useUserInfo';
import { useHeaderVariant } from '../hooks/useHeaderVariant';

interface IProps {
  resource: ResourceNames;
  flags?: LaunchDarklyFlags;
}

interface IState {
  id: string;
  resource: ResourceNames;
  isLoading: boolean;
  config?: IConfigs;
  selectedBlocks: ISelectedBlocks;
  isEditingField: boolean;
  fetchError?: string;
  done: boolean;
  saving: boolean;
  blocks: IBlocks;
  groups: IGroups;
  copyCache: ICopyCache;
  currentDocument: string;
  currentPage: number;
  blockGroups: IGroups;
  pageDimensions: IPageDimensions;
  inputFocused: boolean;
  settings?: ISettings;
  settingsFetched: boolean;
  recipientsFetched: boolean;
  recipientsModal: IRecipients;
  isTextBlockEditingEnabled: boolean;
  status: string;
  isAutoSaving: boolean;
  mobileSigningOrderOpen: boolean;
  emailConfig: IEmailConfig;
  readyToSendModalOpen: boolean;
  successShow: boolean;
  errorShow: boolean;
  zoom: number;
}

const senderBuildSelector = (state: IRootState) => ({
  id: state.senderBuild.id,
  resource: state.senderBuild.resource,
  isLoading: state.pageFrame.loading,
  config: state.senderBuild.config,
  selectedBlocks: state.senderBuild.selectedBlocks,
  isEditingField: state.senderBuild.isEditingField,
  fetchError: state.senderBuild.fetchError,
  done: state.senderBuild.done,
  saving: state.senderBuild.saving,
  blocks: state.senderBuild.blocks,
  groups: state.senderBuild.groups,
  copyCache: state.senderBuild.copyCache,
  currentDocument: state.senderBuild.currentDocument,
  currentPage: state.senderBuild.currentPage,
  blockGroups: state.senderBuild.groups,
  pageDimensions: state.senderBuild.pageDimensions,
  inputFocused: state.senderBuild.inputFocused,
  settings: state.pageFrame.settings,
  settingsFetched: state.pageFrame.settingsFetched,
  recipientsFetched: state.senderBuild.recipientsFetched,
  recipientsModal: state.senderBuild.recipientsModal,
  isTextBlockEditingEnabled: state.senderBuild.isTextBlockEditingEnabled,
  status: state.senderBuild.status,
  isAutoSaving: state.senderBuild.isAutoSaving,
  mobileSigningOrderOpen: state.senderBuild.mobileSigningOrderOpen,
  emailConfig: state.senderBuild.emailConfig,
  readyToSendModalOpen: state.senderBuild.readyToSendModalOpen,
  successShow: state.pageFrame.successShow,
  errorShow: state.pageFrame.errorShow,
  zoom: state.senderBuild.zoom,
});

const SenderBuild = (props: IProps) => {
  const state: IState = useShallowSelector(senderBuildSelector);
  const userInfo = useUserInfo();
  const isFullScreen = isMediumScreenMediaQuery();
  const isSmallScreen = isSmallScreenMediaQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const { useDefaultHeader } = useHeaderVariant();
  const { flags } = props;
  const params = useParams<{ id: string }>();
  const headerContext = React.useContext(HeaderContext);
  const isFirefox = navigator.userAgent.match('Firefox');
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];
  const [isInfoBoxDialogOpen, setIsInfoBoxDialogOpen] = useState(true);
  // Flag to indicate whether a new envelope fetch has been performed, triggering a recipient fetch
  const [newEnvelopeFetch, setNewEnvelopeFetch] = useState(false);
  const isMobileApp = isSkySlopeMobileApp();
  const postPrepareForSignMessage = () =>
    postWindowMessage({
      header_text: 'Prepare for Signature',
      back_button_type: '<',
      should_back_button_exit: true,
      button_text: 'Next',
      // status: PostWindowMessageStatus.DIGISIGN_PREPARE_FOR_SIGNATURE,
    });

  const toggleModal = (skipCloseModal = false) => {
    if (!skipCloseModal) dispatch(actions.toggleReadyToSendModal());
    const sb = state.selectedBlocks;
    if (sb.blockIndices.length > 0) {
      dispatch(actions.selectBlocks(sb.documentId!, sb.pageIndex!, []));
    }
  };

  window.mobileApp.nextButtonTap = () => {
    if (!state.mobileSigningOrderOpen && !state.saving) {
      toggleModal();
    }
  };

  useEffect(() => {
    window.mobileApp.nextButtonTap = () => {};
    window.mobileApp.backButtonTap = () => {};

    // Setting the initial state for the store state
    dispatch(actions.reset());
  }, []);

  useEffect(() => {
    if (isFullScreen && !isSmallScreen) {
      dispatch(actions.setZoom(1.5));
    } else if (isSmallScreen) {
      dispatch(actions.setZoom(0.6));
    } else {
      dispatch(actions.setZoom(1.25));
    }
  }, [isFullScreen, isSmallScreen]);

  useEffect(() => {
    if (!state.settingsFetched || isMobileApp) {
      return;
    }
    headerContext.setContent(
      <NavHeader
        labelName=""
        progressBarPercent={state.settings?.account.userOrigin === UserOrigins.Forms ? 0 : 100}
        hideNavItems={hideNavItems}
        showBreezeApp={showBreezeApp}
        userOrigin={state.settings?.account.userOrigin}
        preferencesUrl={preferencesUrl}
        showPreferences={showPreferencesMenuItem}
        getUsersGroups={getUsersGroups}
        flags={flags}
        useDefaultHeader={useDefaultHeader}
      />
    );
    return () => {
      headerContext.setContent(null);
    };
  }, [state.settings]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const charCode = e.key ? e.key.toLowerCase() : '';
      const { inputFocused } = state;
      if (state.selectedBlocks.blockIndices.length > 0 && !inputFocused) {
        const copyCommand = (e.ctrlKey && charCode === 'c') || (e.metaKey && charCode === 'c');
        const selectedPageBlocks = state.blocks[state.selectedBlocks.documentId!][state.selectedBlocks.pageIndex!];
        if (copyCommand && !state.isTextBlockEditingEnabled) {
          const copiedBlocks = state.selectedBlocks.blockIndices.map((i) => selectedPageBlocks[i]);
          dispatch(actions.copyBlocksToCache(copiedBlocks));
        }
      }
      if (state.selectedBlocks.blockIndices.length) {
        const block =
          state.blocks[state.selectedBlocks.documentId!][state.selectedBlocks.pageIndex!][
            state.selectedBlocks.blockIndices[0]
          ];
        if (!inputFocused && block.blockType === BLOCK_TYPE_KEYS.TEXT) {
          const invalidKeys = [
            'Tab',
            'CapsLock',
            'Shift',
            'Control',
            'Alt',
            'Meta',
            'ArrowLeft',
            'ArrowRight',
            'ArrowUp',
            'ArrowDown',
            'Enter',
            'Backspace',
            'Delete',
            'Escape',
          ];
          const copyCommand = (e.ctrlKey || e.metaKey) && charCode === 'c';
          const pasteCommand = (e.ctrlKey || e.metaKey) && charCode === 'v';
          if (
            !invalidKeys.some((key) => e.key === key) &&
            !state.isTextBlockEditingEnabled &&
            !copyCommand &&
            !pasteCommand
          ) {
            const formattedValue = e.shiftKey ? charCode.toUpperCase() : charCode;
            // @ts-ignore
            const value = block.value ? block.value!.concat(formattedValue) : formattedValue;
            if (isFirefox) {
              dispatch(
                actions.editBlock(
                  state.selectedBlocks.documentId!,
                  state.selectedBlocks.pageIndex!,
                  state.selectedBlocks.blockIndices[0],
                  { value }
                )
              );
            }
            dispatch(actions.enableTextEditing());
          }
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown, false);
  });

  useEffect(() => {
    const handlePaste = (e: KeyboardEvent) => {
      const charCode = e.key ? e.key.toLowerCase() : '';
      const { pasteCounter } = state.copyCache;
      const { copyCache, blocks, selectedBlocks, currentDocument, currentPage, pageDimensions, inputFocused } = state;
      const pasteCommand =
        copyCache.blocks.length && ((e.metaKey && charCode === 'v') || (e.ctrlKey && charCode === 'v'));
      if (pasteCommand && !inputFocused && !state.isTextBlockEditingEnabled) {
        const pasteBlocksObj = pasteCacheBlocks({
          pasteCounter,
          copyCache,
          blocks,
          pageHeight: pageDimensions[currentDocument][currentPage].height,
          pageWidth: pageDimensions[currentDocument][currentPage].width,
          selectedBlocks,
          currentDocument,
          currentPage,
          envelopeId: state.id,
          blockGroups: state.blockGroups,
        });
        if (pasteBlocksObj.updatedGroup!.newBlock!.length > 0) {
          dispatch(
            actions.addNewBlocksToGroup(
              pasteBlocksObj.updatedGroup!.currentGroup,
              pasteBlocksObj.updatedGroup!.newBlock
            )
          );
        } else {
          dispatch(
            actions.pasteBlocks(
              state.currentDocument,
              state.currentPage,
              pasteBlocksObj.blocksWithNewIds,
              pasteBlocksObj.newGroups
            )
          );
        }
      }
    };
    document.addEventListener('keydown', handlePaste, false);
    return () => document.removeEventListener('keydown', handlePaste, false);
  });

  useEffect(() => {
    const keyboardHandler = (e: KeyboardEvent) => {
      if (state.selectedBlocks.blockIndices.length) {
        const sb = state.selectedBlocks;
        if (
          !state.isTextBlockEditingEnabled &&
          !state.inputFocused &&
          !state.isAutoSaving &&
          (e.key === 'Delete' || e.key === 'Backspace')
        ) {
          e.preventDefault();
          const blockIds = sb.blockIndices
            .reverse()
            .map((i) => state.blocks[sb.documentId!][sb.pageIndex!][i].blockId!);
          dispatch(actions.deleteBlocks(sb.documentId!, sb.pageIndex!, blockIds));
        }
      }
    };
    document.addEventListener('keydown', keyboardHandler, false);
    return () => document.removeEventListener('keydown', keyboardHandler, false);
  }, [
    state.selectedBlocks.blockIndices,
    state.blocks,
    state.inputFocused,
    state.isTextBlockEditingEnabled,
    state.isAutoSaving,
  ]);

  // Manage changes in userInfo (initial and after token refresh) and handle envelope info fetch
  useEffect(() => {
    // userInfo has been fetched, but envelope info hasn't been fetched yet
    if (!state.id && userInfo?.provisioned) {
      setNewEnvelopeFetch(true);
      dispatch(actions.fetch(params.id, props.resource, userInfo!));
      if (isFullScreen && !isSmallScreen) {
        dispatch(actions.setZoom(1.5));
      } else if (isSmallScreen) {
        dispatch(actions.setZoom(0.6));
      }
    }

    // Envelope info has been fetched; now fetch recipients
    if (newEnvelopeFetch && state.id && state.resource === ResourceNames.ENVELOPE) {
      dispatch(actions.fetchRecipients(state.id));
      setNewEnvelopeFetch(false);
    }
  }, [userInfo, state.id]);

  // If there is a change in userInfo that is not undefined and envelope info was fetch,
  // it means that the token was reset
  // Reset the state to trigger the calls to get envelope info and recipients
  useEffect(() => {
    if (userInfo?.provisioned && state.id) {
      dispatch(actions.reset());
    }
  }, [userInfo]);

  if (state.done && state.status === 'Sent' && isMobileApp) {
    postWindowMessage({ status: 'sent' });
  } else if (state.status === 'Error' && isMobileApp) {
    postWindowMessage({
      status: 'error',
      message: 'There was an error sending the envelope.',
    });
  }

  if (state.fetchError) {
    history.push('/');
  }

  useEffect(() => {
    if (isMobileApp && !state.saving && !state.readyToSendModalOpen && !state.mobileSigningOrderOpen && !state.done) {
      postPrepareForSignMessage();
    }
  }, [state.saving, state.readyToSendModalOpen, state.mobileSigningOrderOpen]);

  if (
    state.isLoading ||
    !state.settingsFetched ||
    (state.resource === ResourceNames.ENVELOPE && !state.recipientsFetched)
  ) {
    return null;
  }

  if (state.done) {
    switch (state.settings?.account.userOrigin) {
      case UserOrigins.DOP:
        return <Done />;
      default:
        return <EnvelopeSent fullScreen={isFullScreen} flags={flags} />;
    }
  }

  if (
    (state.status === 'Sent' || state.status === 'InProgress') &&
    state.settings?.account.userOrigin !== UserOrigins.DOP
  ) {
    return <EnvelopeSent fullScreen={isFullScreen} flags={flags} />;
  }

  const sendEnvelopeForMobileApp = async (emailConfig: { subject: string; body: string }) => {
    toggleModal(true);
    // this doesn't actually send the envelope, it just validates the blocks
    const updatedBlocksAndGroups = await validateBlocksAndSendEnvelope(state.blocks, state.groups, true, state.zoom);

    dispatch(
      actions.validateBlocksBeforeSending(
        updatedBlocksAndGroups.updatedBlocks,
        updatedBlocksAndGroups.updatedGroups,
        emailConfig,
        true
      )
    );
  };

  return (
    <div style={{ width: '100%' }}>
      {isFullScreen ? '' : <EnvelopeShelf />}
      {isFullScreen ? '' : <BlockShelfWrapper />}
      {state.readyToSendModalOpen && isMobileApp && (
        <EditEmailModal
          sendEnvelopeForMobileApp={sendEnvelopeForMobileApp}
          handleBackButtonTap={toggleModal}
          fullScreen
        />
      )}
      <PdfViewer />
      <Toolbar />
      {state.saving && isMobileApp ? '' : <FooterControls />}
      {state.saving && <SavingOverlay />}
      {state.mobileSigningOrderOpen && <MobileRecipientsOrder recipients={state.recipientsModal} id={state.id} />}
      {state.config?.senderUi?.infoBox && (
        <InfoBoxDialog
          isOpen={isInfoBoxDialogOpen}
          onClose={() => setIsInfoBoxDialogOpen(false)}
          infoBox={state.config?.senderUi?.infoBox}
        />
      )}
    </div>
  );
};

export default withLaunchDarkly(SenderBuild);
