import React, { useRef, useEffect } from 'react';
import { useAutomaticFontSizing } from '../../lib/useAutomaticFontSizing';
import { useDispatch } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import { IBlock } from '../../store/senderBuild/types';
import { ContentState, Editor, EditorState } from 'draft-js';

interface IProps {
  block: IBlock;
  zoom: number;
  documentId: string;
  pageIndex: number;
  blockIndex: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.DraftEditor-root': {
      height: '100%',
    },
    '.DraftEditor-editorContainer': {
      height: '100%',
    },
    '.public-DraftEditor-content': {
      height: '100%',
    },
  },
  innerBlock: {
    borderRadius: '1px',
    whiteSpace: 'normal',
    lineHeight: 1,
    color: colors.grey[900],
    wordBreak: 'break-word',
    fontFamily: 'courier',
    fontWeight: 'bold',
    '&[contenteditable]': {
      '-webkit-user-select': 'text',
      'user-select': 'text',
      '-moz-user-select': 'text !important',
      '-khtml-user-select': 'text',
      '-o-user-select': 'text',
    },
    ':before': {
      position: 'absolute',
    },
  },
  preventOverflow: {
    height: '100%',
    overflow: 'hidden',
    outline: '0px solid transparent',
    overflowWrap: 'break-word',
  },
  editor: {
    height: '100%',
  },
  ['DraftEditor-root']: {
    height: '100%',
    width: '100%',
  },
  ['DraftEditor-editorContainer']: {
    height: '100%',
  },
}));

export const TextBlockEditing = (props: IProps) => {
  const isFirefox = navigator.userAgent.match('Firefox');
  const dispatch = useDispatch();
  const classes = useStyles();
  const firstRender = useRef(true);
  const editor = useRef(null);
  const { fontSize, value, setValue } = useAutomaticFontSizing({
    height: props.block.height!,
    width: props.block.width!,
    zoom: props.zoom,
    initialFontSize: props.block.fontSize!,
    initialValue: props.block.value as string,
  });

  const debouncedFontSize = fontSize;
  const debouncedValue = value;

  const [editorState, setEditorState] = React.useState(() => {
    if (!props.block.value) {
      return EditorState.createEmpty();
    } else {
      return EditorState.createWithContent(ContentState.createFromText(props.block.value as string));
    }
  });

  useEffect(() => {
    editor.current.focus();
    setEditorState((prevEditorState: EditorState) => EditorState.moveFocusToEnd(prevEditorState));
    return () => {
      // AP-277: this is disabling the block on placement
      dispatch(actions.disableTextEditing());
    };
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (value !== props.block.value && !props.block.isCreating) {
      dispatch(actions.editBlock(props.documentId, props.pageIndex, props.blockIndex, { fontSize, value }));
    }
  }, [debouncedValue, debouncedFontSize]);

  const handleTextChange = (e: EditorState) => {
    if (!props.block.value && !isFirefox) {
      const plainTextValue: string = e.getCurrentContent().getPlainText();
      const updatedState = EditorState.createWithContent(ContentState.createFromText(plainTextValue));
      const updatedEvent = EditorState.set(updatedState, {});
      setEditorState(updatedEvent);
      setValue(plainTextValue);
      setEditorState((prevEditorState: EditorState) => EditorState.moveFocusToEnd(prevEditorState));
    } else {
      setEditorState(e);
      const newValue = e.getCurrentContent().getPlainText();
      if (props.block.value !== newValue) {
        setValue(newValue);
      }
    }
  };

  const handleOnFocus = () => {
    editor.current.focus();
  };

  return (
    <div
      className={`${classes.innerBlock} ${classes.preventOverflow} block-${props.block.blockId}`}
      onFocus={handleOnFocus}
      id={`textBlock-${props.block.blockId}`}
      style={{
        fontSize: debouncedFontSize * props.zoom,
        wordBreak: 'break-word',
        height: '100%',
        width: '100%',
        padding: '0 4px',
        outline: 'none',
      }}
    >
      <Editor ref={editor} editorState={editorState} onChange={handleTextChange} />
    </div>
  );
};
